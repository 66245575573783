<template>
  <div>
    <head-layout :head-btn-options="headBtnOptions"
                 :head-title="$route.meta.title"
                 @head-save="headSave('save')"
                 @head-save-back="headSave('back')"
                 @head-cancel="headCancel"
    ></head-layout>
    <div>
      <el-form ref="ruleForm" :model="form" :rules="rules" label-width="120px">
        <el-row>
          <el-col :span="8">
            <el-form-item label="数据类型">
              <el-select v-model="sjtype"  placeholder="数据类型" @change="CHsjtype">
                <el-option label="全量" value="1"></el-option>
                <el-option label="增量" value="2"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item :label="$t('cip.dc.transformation.field.infCode')" prop="infCode">
              <el-select v-model="form.infCode" :placeholder="$t('cip.dc.transformation.field.infCode')" >
                <el-option v-for="(item,index) in selDat" :key="index" :label="item.infDesc" :value="item.infCode"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item :label="$t('cip.dc.transformation.field.fieldName')">
              <el-input v-model="form.fieldName" :placeholder="$t('cip.dc.transformation.field.fieldName')" autocomplete="off" ></el-input>
            </el-form-item>
          </el-col>

        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item :label="$t('cip.dc.transformation.field.targetTable')" prop="targetTable">
              <el-input v-model="form.targetTable" :placeholder="$t('cip.dc.transformation.field.targetTable')" autocomplete="off" ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item :label="$t('cip.dc.transformation.field.srcField')" prop="srcField">
              <el-input v-model="form.srcField" :placeholder="$t('cip.dc.transformation.field.srcField')" autocomplete="off" ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item :label="$t('cip.dc.transformation.field.targetField')" prop="targetField">
              <el-input v-model="form.targetField" :placeholder="$t('cip.dc.transformation.field.targetField')" autocomplete="off" ></el-input>
            </el-form-item>
          </el-col>

        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item :label="$t('cip.dc.transformation.field.targetField')">
              <el-select v-model="form.unitCode" :placeholder="$t('cip.dc.transformation.field.targetField')" >
                <el-option v-for="(item,index) in selData" :key="index" :label="item.name" :value="item.code"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">

            <el-form-item :label="$t('cip.dc.transformation.field.mapCode')">
              <el-select v-model="form.keycolumn" :placeholder="$t('cip.dc.transformation.field.mapCode')" >
                <el-option v-for="(item,index) in selData" :key="index" :label="item.name" :value="item.code"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item :label="$t('cip.dc.transformation.field.groupNo')">
              <el-input v-model="form.groupNo" :placeholder="$t('cip.dc.transformation.field.groupNo')" autocomplete="off" ></el-input>
            </el-form-item>
          </el-col>

        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item :label="$t('cip.dc.transformation.field.defaultValue')">
              <el-input v-model="form.defaultValue" :placeholder="$t('cip.dc.transformation.field.defaultValue')" autocomplete="off" ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item :label="$t('cip.dc.transformation.field.dimension')">
              <el-input v-model="form.dimension" :placeholder="$t('cip.dc.transformation.field.dimension')" autocomplete="off" ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item :label="$t('cip.dc.transformation.field.dimensionSed')">
              <el-input v-model="form.dimensionSed" :placeholder="$t('cip.dc.transformation.field.dimensionSed')" autocomplete="off" ></el-input>
            </el-form-item>
          </el-col>
        </el-row>




      </el-form>
    </div>
  </div>
</template>

<script>
import {dataItfMapSel, dataItfUnitSel, dataSplitSel, getzhcode, itfTransUp} from "@/api/dataAcquisition";

export default {
  name: "dataCoonfigAdd",
  data() {
    return {
      headBtnOptions: [{
        label: this.$t('cip.cmn.btn.saveBtn'),
        emit: "head-save",
        type: "button",
        icon: ""
      },
        {
          label: this.$t('cip.cmn.btn.saveBackBtn'),
          emit: "head-save-back",
          type: "button",
          icon: ""
        }, {
          label: this.$t('cip.cmn.btn.celBtn'),
          emit: "head-cancel",
          type: "button",
          icon: ""
        }],
      rules: {
        infCode: [
          {required: true, message: this.$t('cip.dc.transformation.field.aggregationCode'), trigger: 'change'}
        ],
        targetTable: [
          {required: true, message: this.$t('cip.dc.transformation.field.targetTagName'), trigger: 'change'}
        ],
        srcField: [
          {required: true, message: this.$t('cip.dc.transformation.field.sourceField'), trigger: 'change'}
        ],
        targetField: [
          {required: true, message: this.$t('cip.dc.transformation.field.PleasetargetField'), trigger: 'change'}
        ],
      },
      sjtype: "",
      form: {
        code: "",
        "infCode": "",
        "fieldName": "",
        "targetTable": "",
        "srcField": "",
        "targetField": "",
        "unitCode": "",
        "mapCode": "",
        "groupNo": '',
        "defaultValue": "",
        "dimension": "",
        "dimensionSed": ""
      },
      formLabelWidth: '120px',
      selData: [],
      selData1: [],
      selData2: [],
      selDat: [],
    }
  },
  created() {
    if (this.$route.query.id) {
      this.form = JSON.parse(sessionStorage.getItem('transformEditObj'));
      console.log(this.form,"ssddasdasd");
      this.$route.meta.title = this.$t('cip.dc.transformation.field.conversionEditing')
    } else {
      this.$route.meta.title = this.$t('cip.dc.transformation.field.DataConversionAdd')
    }

    this.dataItfMapSelFn()
    this.dataItfUnitSelFn()
    // this.datSoSelFn();
  },
  methods: {
    CHsjtype(e) {
      getzhcode({type: e}).then(res => {
        console.log(res.data);
        this.selDat = res.data.data;
        this.form.infCode = ''
      })

    },
    dataItfMapSelFn() {
      dataItfMapSel().then((res) => {
        this.selData = res.data.data;
      })
    },
    dataItfUnitSelFn() {
      dataItfUnitSel().then((res) => {
        this.selData1 = res.data.data;
      })
    },
    datSoSelFn() {
      dataSplitSel().then((res) => {
        this.selDat = res.data.data;
      })
    },
    headSave(type) {
      this.$refs['ruleForm'].validate((valid) => {
        if (valid) {
          if (this.form.id) {
            itfTransUp(this.form).then(() => {
              this.$message.success(this.$t('transformation.fun.ModifiedSuccessfully'));
              if (type == 'back') {
                this.headCancel()
              }
            })
          } else {
            itfTransUp(this.form).then(() => {
              this.$message.success(this.$t('transformation.fun.SuccessfullyAdded'));
              if (type == 'back') {
                this.headCancel()
              }
            })
          }
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    headCancel() {
      this.$router.$avueRouter.closeTag();
      this.$router.back();
    },

  }
}
</script>

<style scoped>

</style>
